import React, { useEffect, useState } from "react";
import { format } from "timeago.js";

export default function LatestCommit() {
    const [commitMsg, setCommitMsg] = useState({});

    useEffect(() => {
        const fetchCommit = async () => {
            const email = "shaunlimweimin@gmail.com";
            const username = "ShaunLWM";
            const response = await fetch(`https://api.github.com/users/${username}/events/public`);
            const json = await response.json();
            let latestCommit;
            const latestPushEvent = json.find(event => {
                if (event.type !== 'PushEvent') return false;
                latestCommit = event.payload.commits.reverse().find(commit => commit.author.email === email);
                return Boolean(latestCommit);
            });

            if (!latestCommit) {
                setCommitMsg({ url: null });
            } else {
                const { repo, created_at: createdAt } = latestPushEvent;
                const repoUrl = `https://github.com/${repo.name}`;
                const commitMessageLines = latestCommit.message.trim().split('\n');
                const obj = {
                    url: `${repoUrl}/commit/${latestCommit.sha}`,
                    title: commitMessageLines.slice(1).join('\n').trim(),
                    body: commitMessageLines[0].trim(),
                    time: format(createdAt),
                    repoUrl: repoUrl,
                    repoTitle: repo.name.replace(new RegExp(`^${username}/`), '')
                }

                setCommitMsg(obj);
            }
        }

        fetchCommit();
    }, []);
    return (
        <>
            {
                commitMsg["url"] === null ? <span>Latest commit: no recent commit</span> :
                    <span>Latest commit: <a href={commitMsg["url"]}>{commitMsg["body"]}</a> {commitMsg["time"]} in <a href={commitMsg["repoUrl"]}>{commitMsg["repoTitle"]}</a></span>
            }
        </>
    );
}
