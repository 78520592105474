import SvgIcon from "@material-ui/core/SvgIcon";
import React, { useState } from "react";

export default function SocialMedia({ index, value }) {
    const [isHover, setHover] = useState("black");
    return (
        <div key={index} className="profile-sm">
            <a href={value["link"]}>
                <SvgIcon className="sm-icon" onMouseOver={() => setHover(value["color"])} onMouseLeave={() => setHover("black")} style={{ fill: isHover }}>{value["svg"]}</SvgIcon>
            </a>
        </div>
    )
}
