import React from "react";
import useDarkMode from "../store/useDarkMode";

export default function ProfilePicture() {
    const [, toggleDarkMode] = useDarkMode();

    return (
        <div className="profile">
            <img className="profile-pic pulsing-effect" src="/img/me.jpg" alt="Me!" onClick={toggleDarkMode} />
        </div>
    )
}
