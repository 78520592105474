import LinearProgress from "@material-ui/core/LinearProgress";
import React, { useEffect, useState } from "react";
import RepositoryCard from "./RepositoryCard";
import { isDev } from "../store/Utils";

const GITHUB_API = "https://api.github.com/users/shaunlwm/repos";

export default function RepositoryContainer({ classes, index, repo }) {
	const [getRepos, setRepos] = useState([]);
	const [isGetReposLoading, setGetReposLoading] = useState(true);

	useEffect(() => {
		const fetchServerAPI = async () => {
			try {
				const url = isDev() ? "http://localhost:8081/api/github" : "https://shaunlwm.me:8081/api/github";
				const response = await fetch(url);
				const result = await response.json();
				setRepos(result);
				setGetReposLoading(false);
			} catch (error) {
				console.error(error);
				fetchGithubRepos();
			}
		};

		const fetchGithubRepos = async () => {
			try {
				const response = await fetch(GITHUB_API);
				const result = await response.json();
				const json = result.sort((a, b) => b.stargazers_count - a.stargazers_count);
				setRepos(json.slice(0, 10));
				setGetReposLoading(false);
			} catch (error) {
				console.error(error);
			}
		};

		fetchServerAPI();
	}, []);

	if (isGetReposLoading) return <LinearProgress style={{ margin: "20px", width: "100%" }} />;
	return getRepos.map((repo, index) => <RepositoryCard key={repo["name"]} repo={repo} index={index} />);
}
