import SvgIcon from "@material-ui/core/SvgIcon";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

export default function ProgrammingIcon({ index, value }) {
    return (<div key={index} className="profile-sm">
        <Tooltip title={value["name"]}>
            <SvgIcon className="sm-icon" style={{ fill: value["color"] }}>{value["svg"]}</SvgIcon>
        </Tooltip>
    </div>)
}
