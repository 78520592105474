import LinearProgress from "@material-ui/core/LinearProgress";
import React, { useEffect, useState } from "react";
import { format } from "timeago.js";

const LASTFM_API = "https://ws.audioscrobbler.com/2.0/?method=user.getrecenttracks&user=shaunidiot&api_key=66c71650cfbf8f47e2a3ec08f2ca8c0b&format=json&limit=1";

export default function ProfilePicture() {
    const [getLastPlayed, setLastPlayed] = useState({ artist: "Boyce Avenue", track: "Shallow", link: "#", date: "1 hour ago" });
    const [isLastPlayedLoading, setLastPlayedLoading] = useState(true);

    useEffect(() => {
        const fetchLastPlayed = () => {
            fetch(LASTFM_API).then(res => res.json()).then(json => {
                if (json["recenttracks"]["@attr"]["total"] === "0") return;
                let date = typeof json["recenttracks"]["track"][0]["date"] === "undefined" ? "Few seconds ago" : format(parseInt(json["recenttracks"]["track"][0]["date"]["uts"]) * 1000);
                setLastPlayed({
                    date,
                    track: json["recenttracks"]["track"][0]["name"],
                    artist: json["recenttracks"]["track"][0]["artist"]["#text"],
                    link: json["recenttracks"]["track"][0]["url"]
                });
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                return setLastPlayedLoading(false);
            });
        };

        fetchLastPlayed();
    }, []);

    if (isLastPlayedLoading) return (<LinearProgress style={{ "margin": "20px" }} />);
    return (
        <h4 style={{ marginBottom: "35px" }}>{getLastPlayed["date"]}, I listened to <a className="afx" href={getLastPlayed["link"]}><u>{getLastPlayed["track"]}</u></a> by {getLastPlayed["artist"]}</h4>
    )
}
