import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { format } from "timeago.js";

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));


export default function RepositoryCard({ index, repo }) {
    const classes = useStyles();
    return (
        <Grid key={index} item xs={12} sm={6} zeroMinWidth>
            <Card className={classes.card} variant="outlined">
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>{format(new Date(repo["updated_at"]).getTime())}</Typography>
                    <Typography variant="h5" component="h2">{repo["name"]}</Typography>
                    <Typography className={classes.pos} color="textSecondary">{repo["stargazers_count"]} stars</Typography>
                    <Typography variant="body2" component="p" textOverflow="ellipsis" noWrap>
                        {repo["description"]}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={() => window.location = repo["html_url"]}> Github</Button>
                </CardActions>
            </Card>
        </Grid>
    )
}
