let theme = {
  "palette": {
    "type": "dark"
  }
}

export function createTheme(type) {
  return {
    ...theme,
    palette: {
      ...theme.palette,
      type,
    },
  };
}

const isDev = () => process.env.NODE_ENV === "development";

export { theme, isDev };
